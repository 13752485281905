* {
    box-sizing: border-box;
    font-family: sans-serif;
}

html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
}

#v-go-web-root {
    background-color: #f2f2f2;
    height: 100%;
    overflow-y: auto;
    position: relative;
}

.page {
    display: flex;
    min-height: 100%;
    position: relative;

    @media (max-width: 1024px) {
        flex-direction: column;
    }
}

.page.no-scroll {
    max-height: 100vh;
    overflow-y: hidden;
}

.page img {
    height: auto;
    max-height: 100vh;
    max-width: 100%;
}

.menu-mask {
    height: 100%;
    position: fixed;
    z-index: 1;

    @media (max-width: 1024px) {
        height: auto;
        overflow-y: auto;
        width: 100%;
    }
}

.menu-mask.open {
    backdrop-filter: blur(3px);
    width: 100%;

    @media (max-width: 1024px) {
        height: 100%;
    }
}

.menu {
    align-items: center;
    background-color: rgba(255, 255, 255, 0.15);
    border-bottom-right-radius: 50px;
    color: white;
    display: flex;
    backdrop-filter: blur(5px);
    flex-direction: column;
    height: 100%;
    left: 0;
    padding: 25px 0 75px 0;
    position: relative;
    width: 50px;

    @media (max-width: 1024px) {
        align-items: flex-start;
        height: 50px;
        justify-content: center;
        padding: 0 75px 0 25px;
        width: auto;
    }
}

.menu-mask.open > .menu {
    padding: 25px 25px 75px 25px;
    width: 250px;
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;

    @media (max-width: 1024px) {
        height: auto;
    }
}

.menu-item-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.menu-item + .menu-item {
    margin-top: 15px;
}

.menu-item > a {
    color: #2c2e33;
    text-decoration: none;
    font-weight: bold;
}

.menu-item--active > a {
    color: #3CA874;
}

.page-content,
.sidebar {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.page-content {
    width: 66.66%;
    padding: 50px 0;
    position: relative;

    @media (max-width: 1024px) {
        width: 100%;
    }
}

.page-content.no-padding {
    @media (max-width: 1024px) {
        padding: 0;
    }
}

.sidebar {
    background-image: radial-gradient(#656572 0%, #2c2e33 100%);
    color: white;
    flex-grow: 1;
    padding: 50px 75px;
    width: 33.33%;

    @media (max-width: 1024px) {
        justify-content: center;
        padding-top: 100px;
        width: 100%;
    }
}

h1 {
    margin: 0 0 25px 0;
}

.panel {
    background-color: white;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.panel--primary {
    width: 500px;
    padding: 50px 75px;

    @media (max-width: 1024px) {
        padding: 50px 30px;
        width: 100% !important;
    }
}

.panel__title {
    margin: 0 0 50px 0;
}

.panel--secondary {
    border: #3CA874 solid 1px;
    border-radius: 15px;
    padding: 30px;
    width: 500px;

    @media (max-width: 1024px) {
        width: 100%;
    }
}

.panel--primary > .panel--secondary {
    width: 100%;
}

.sidebar > .panel--secondary {
    color: initial;
    max-width: 300px;
    width: auto;
}

.row {
    display: flex;
    justify-content: center;
    width: 100%;
}

.column {
    display: flex;
    justify-content: center;
    /* to-do: make flexible */
    min-width: 50%;
    padding: 0 10px;
}

.row > .column:first-child {
    padding-left: 0;
}

.row > .column:last-child {
    padding-right: 0;
}

form {
    width: 100%;
}

input[type="text"],
input[type="password"],
select,
.input-button {
    background-color: white;
    border: #3CA874 solid 1px;
    border-radius: 25px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
    display: block;
    height: 50px;
    padding: 0 15px;
    text-align: left;
    /* to-do: use custom arrow */
    -webkit-appearance: none;
    width: 100%;
}

input:focus,
select:focus {
    outline: none;
}

input:disabled {
    background-color: rgba(0, 0, 0, 0.15);
}

a {
    color: black;
}

.primary-button,
.name-tag {
    background-image: linear-gradient(to left, #3CA874, #60B45E);
    border: none;
    border-radius: 25px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
    font-weight: bold;
    line-height: 50px;
    min-width: 250px;
    padding: 0 30px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}

.primary-button--white {
    background-color: white;
    background-image: none;
    border: 1px solid #3CA874;
    color: #3CA874;
}

.primary-button:hover {
    cursor: pointer;
    background-image: linear-gradient(to left, rgba(60, 168, 116, 0.85), rgba(96, 180, 94, 0.85));
}

.primary-button:active {
    background-image: linear-gradient(to right, #3CA874, #60B45E);
}

.primary-button:disabled,
.primary-button.disabled {
    background: linear-gradient(to left, #A9B4C4, #838993);
}

.secondary-button {
    background-color: white;
    border: gray solid 1px;
    border-radius: 20px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    padding: 0 15px;
    height: 40px;
    flex-shrink: 0;
}

.secondary-button:hover {
    border-color: black;
}

.secondary-button:active {
    box-shadow: none;
}

.horizontal-line {
    border-top: #3CA874 solid 1px;
    margin-bottom: 15px;
}

.progress-bar-container {
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    width: 100%;
}

.progress-bar {
    background-color: #3CA874;
    border-radius: 3px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
    height: 6px;
    width: 0;
}

.loading-icon {
    margin-right: 5px;
}

p {
    margin: 15px 0;
}

p:first-child {
    margin-top: 0;
}

p:last-child {
    margin-bottom: 0;
}

.error-message {
    background-color: #f8d7da;
    border-radius: 15px;
    color: #842029;
    padding: 15px;
    width: 100%;
}

.dialog-container {
    @media (max-width: 1024px) {
        display: flex;
        justify-content: center;
        max-height: 100%;
        padding: 100px 30px 50px;
        width: 100%;
    }
}

.dialog-mask {
    align-items: center;
    backdrop-filter: blur(3px);
    background-color: rgba(255, 255, 255, 0.85);
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
}

.search-result-container {
    max-height: 150px;
    overflow-y: scroll;
    width: 100%;
}

.search-result {
    background-color: #f2f2f2;
    border-radius: 25px;
    line-height: 50px;
    overflow-x: hidden;
    padding: 0 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.search-result + .search-result {
    margin-top: 10px;
}

.search-result:hover {
    background-color: lightgray;
}

.search-result.selected {
    border: 1px solid gray;
}

.search-result__group-header {
    text-transform: uppercase;
    margin: 10px 0;
}

.search-result__group-header:first-child {
    margin-top: 0;
}

.quest-grid {
    align-items: start;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 50px;
    width: 100%;

    @media (max-width: 1024px) {
        grid-template-columns: 1fr;
    }
}

.quest-cell {
    cursor: pointer;
    margin: 25px 50px 25px 50px;
    position: relative;
}

.quest-card {
    background-color: white;
    border: #3CA874 solid 1px;
    border-bottom: none;
    border-radius: 25px 25px 0 0;
    padding: 25px 25px 50px 25px;
}

.quest-cell:hover > .quest-card {
    background-image: linear-gradient(to right, #3CA874, #60B45E);
}

.quest-expiration {
    color: #3CA874;
}

.quest-cell:hover .quest-expiration {
    color: white;
}

.quest-title {
    font-weight: bold;
    text-transform: uppercase;
}

.quest-prize-container {
    background-image: linear-gradient(to right, #3CA874, #60B45E);
    border-radius: 25px;
    bottom: -25px;
    line-height: 50px;
    position: absolute;
    text-align: center;
    width: 100%;
}

.quest-cell:hover > .quest-prize-container {
    background-color: white;
    background-image: none;
    border: #3CA874 solid 1px;
}

.gift-icon {
    background-image: url('./assets/green-gift-icon.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    height: 40px;
    vertical-align: middle;
    width: 40px;
}

.quest-cell:hover .gift-icon {
    background-image: url('./assets/white-gift-icon.svg');
}

.quest-prize {
    color: white;
}

.quest-cell:hover .quest-prize {
    color: #3CA874;
}

.quest-index {
    background-image: linear-gradient(to left, #60B45E, #3CA874);
    border-radius: 25px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
    color: white;
    left: -25px;
    line-height: 50px;
    position: absolute;
    text-align: center;
    top: -25px;
    width: 50px;
}

.quest-flag {
    align-items: center;
    background-color: white;
    border-radius: 25px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
    color: white;
    display: flex;
    justify-content: center;
    height: 50px;
    right: -25px;
    position: absolute;
    top: -25px;
    width: 50px;
}

.popup,
.sponsor-card {
    background-image: linear-gradient(to left, #60B45E, #3CA874);
    border-radius: 15px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 25px 25px 50px 25px;
    position: relative;
}

.sponsor-card img {
    max-height: 250px;
}

.popup-content {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    max-height: 100%;
    overflow-y: auto;
}

.popup-content > img {
    max-height: 150px;
}

.floating-button-container {
    bottom: -25px;
    left: 0;
    position: absolute;
    width: 100%;
}

.popup .primary-button {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
}

.popup h2 {
    text-transform: uppercase;
}

.profile-picture {
    margin-bottom: 25px;
    position: relative;
}

h3 {
    color: #3CA874;
    font-weight: normal;
}

.sponsor-grid {
    column-gap: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 50px;
    row-gap: 50px;
    width: 100%;

    @media (max-width: 1024px) {
        grid-template-columns: 1fr;
    }
}

.leaderboard {
    width: 100%;
}

.leaderboard-item {
    align-items: center;
    border: 1px solid #3CA874;
    border-radius: 20px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
    display: flex;
    line-height: 40px;
    padding: 0 10px 0 5px;
    width: 100%;
}

.leaderboard-item + .leaderboard-item {
    margin-top: 20px;
}

.leaderboard-item-position {
    align-items: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    height: 30px;
    margin-right: 5px;
    width: 30px;
}

.leaderboard-item:first-child > .leaderboard-item-position {
    background-color: gold;
}

.leaderboard-item:nth-child(2) > .leaderboard-item-position {
    background-color: silver;
}

.leaderboard-item:nth-child(3) > .leaderboard-item-position {
    background-color: #CD7F32;
}

.leaderboard-item-name {
    flex-grow: 1;
}

.question {
    padding: 0 100px;

    @media (max-width: 1024px) {
        padding: 0 25px;
    }
}

.question-text {
    background-image: linear-gradient(to left, #60B45E, #3CA874);
    border-radius: 15px;
    color: white;
    padding: 15px;
}

.answer {
    background-color: white;
    border: 1px solid #3CA874;
    border-radius: 20px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    margin: 45px 0 0 20px;
    padding: 30px;
    position: relative;
}

.answer-index {
    align-items: center;
    background-image: linear-gradient(to left, #60B45E, #3CA874);
    border: 1px solid #3CA874;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    height: 40px;
    left: -20px;
    position: absolute;
    top: -20px;
    width: 40px;
}

.answer.selected {
    border-width: 3px;
}

.answer.incorrect > .answer-index {
    background-image: linear-gradient(to left, darkred, crimson);
    border-width: 0;
}

.answer.incorrect.selected {
    border-color: crimson;
}

.video-page-content {
    @media (max-width: 1024px) {
        padding: 0 25px;
    }
}

.video-page-content iframe {
    max-width: 100%;
}
